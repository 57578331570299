<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>邮件管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/accessory' }"
          >邮件附件
        </el-breadcrumb-item>
        <el-breadcrumb-item>新增</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detail-info margin-l-sm">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="附件名称：" prop="name">
          <el-input v-model="form.name" class="input"></el-input>
        </el-form-item>
        <el-form-item label="附件：" prop="url">
          <upload-file
            @onChange="onChange"
            @onSuccess="onSuccess"
          ></upload-file>
          <div class="file">
            <div class="item">名称：{{ fileName }}</div>
            <div class="item item1">大小：{{ unitConversion(form.size) }}</div>
            <div class="item">文件：{{ form.ext }}</div>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button
            :loading="loading"
            size="small"
            type="primary"
            @click="onSubmit"
            >保存
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import UploadFile from "@/components/uploadFile.vue";

export default {
  name: "add",
  components: {
    UploadFile,
  },
  data() {
    return {
      form: {},
      fileName: "",
      rules: {
        name: [
          {
            required: true,
            message: "请输入附件名称",
            trigger: "blur",
          },
        ],
        url: [
          {
            required: true,
            message: "请至少上传一个文件",
            trigger: "change",
          },
        ],
      },
      loading: false,
    };
  },
  methods: {
    ...mapActions("mailbox", ["addAttachmentFile"]),
    unitConversion(size) {
      return (size / 1024 / 1024).toFixed(2) + "M";
    },
    onChange(data) {
      this.tableData = data;
    },
    onSuccess(data) {
      this.form.url = data.path;
      this.form.size = data.size;
      this.fileName = data.path.split(".")[0].split("/")[1];
      this.form.ext = data.path.split(".")[1];
      console.log(this.form);
    },
    //提交表单
    onSubmit() {
      let _this = this;
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            const { res_info } = await this.addAttachmentFile(this.form);
            if (res_info !== "ok") return;
            this.$message.success("创建成功");
            this.$router.go(-1);
          } finally {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.file {
  width: 350px;
  display: flex;
  border: 1px solid #eee;
  justify-content: space-around;
  align-items: center;
}

.file .item {
  padding: 10px;
}

.item1 {
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
}
</style>